import React from "react";

import Slider from "react-slick";

import CommentCarsSlider from "./CommentCardSlider";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ProductCardSlider from "./ProductCardSlider";

const useStyles = makeStyles((theme) => ({
  pad: {
    padding: "15px",

    backgroundColor: "blue",
  },
}));

function CommentSlider() {
  const classes = useStyles();

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    autoplaySpeed: 4000,
    autoplay: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <CommentCarsSlider />
    </Slider>
  );
}

export default CommentSlider;
