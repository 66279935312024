import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import Rating from "@material-ui/lab/Rating";

import logo from "../images/profile.png";

import FavoriteIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles((theme) => ({}));

function TestiminialsCards() {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid
        item
        container
        md={12}
        xs={12}
        sm={12}
        alignItems="center"
        style={{
          margin: "24px",
          backgroundColor: "white",
          borderRadius: "16px",
          padding: "24px",
        }}
      >
        <Grid item md={2} xs={2} sm={2}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "100%",
              borderRadius: "200px",
              maxWidth: "100px",
            }}
          />
        </Grid>

        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ textAlign: "left", paddingLeft: "24px" }}
        >
          <Typography variant="h6">
            <b>Prabhasha Deshappriya</b>
          </Typography>
          <Typography variant="body1" style={{ color: "#CC997C" }}>
            <b>Customer</b>
          </Typography>
          <Typography variant="body2" style={{ color: "#949292" }}>
            <b>December 26 at 06:30 PM </b>
          </Typography>
        </Grid>

        <Grid item md={4} xs={4} sm={4}>
          <Typography variant="body1" color="initial">
            <b> 5.0/5.0</b>
          </Typography>

          <Rating
            name="read-only"
            value={5}
            readOnly
            icon={<FavoriteIcon fontSize="medium" />}
          />
        </Grid>

        <Grid
          item
          container
          md={12}
          xs={12}
          sm={12}
          style={{ padding: "24px", marginTop: "16px" }}
        >
          <Typography variant="body1" color="initial">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum
            purus tellus cursus ac sit amet. Tortor et quisque euismod vitae
            suscipit. Maecenas nulla vel blandit quis. Morbi blandit aliquet sit
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum
            purus tellus cursus ac sit amet. Tortor et quisque euismod vitae
            suscipit. Maecenas nulla vel blandit quis. Morbi blandit aliquet sit
            "
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TestiminialsCards;
