import React from "react";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import { IconButton } from "@material-ui/core";

function HeartIcon({ color, size, onClick, status, id }) {
  if (status) {
    return (
      <IconButton onClick={onClick}>
        <FavoriteOutlinedIcon style={{ color: color, fontSize: size }} />
      </IconButton>
    );
  } else {
    return (
      <IconButton onClick={onClick}>
        <FavoriteBorderIcon style={{ color: color, fontSize: size }} />
      </IconButton>
    );
  }
}

export default HeartIcon;
