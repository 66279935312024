import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

//icons
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";

const useStyles = makeStyles((theme) => ({
  navlink: {
    textDecoration: "none",
    color: "#1b1b28",
    "&:hover ": {
      //   backgroundColor: "#1b1b28",
      color: "#b2102f",
      "& $addBoxIcon": {
        color: "#b2102f",
      },
    },
  },
  addBoxIcon: {
    color: "#1b1b28",
  },
  marginForRight: {
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    },
  },
}));

export default function SettingsSideBar() {
  const classes = useStyles();

  return (
    <Grid item md={3} sm={12} xs={12}>
      <div
        className={classes.marginForRight}
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
          textAlign: "left",
        }}
      >
        <List style={{ paddingTop: "-8px", paddingBottom: "-8px" }}>
          <NavLink to="/settings">
            <ListItem button className={classes.navlink}>
              <ListItemIcon>
                <AccountCircleOutlinedIcon className={classes.addBoxIcon} />
              </ListItemIcon>
              <ListItemText primary="User Details" />
            </ListItem>
          </NavLink>
          <Divider />

          <NavLink to="/orders">
            <ListItem button className={classes.navlink}>
              <ListItemIcon>
                <LocalShippingOutlinedIcon className={classes.addBoxIcon} />
              </ListItemIcon>
              <ListItemText primary="My Orders" />
            </ListItem>
          </NavLink>
          <Divider />

          <NavLink to="/wishlist">
            <ListItem button className={classes.navlink}>
              <ListItemIcon>
                <FavoriteBorderOutlinedIcon className={classes.addBoxIcon} />
              </ListItemIcon>
              <ListItemText primary="Wish List" />
            </ListItem>
          </NavLink>
        </List>
      </div>
    </Grid>
  );
}
