import React from "react";

import { Typography, Grid, Divider, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: "0px",
    color: "#FF0000",
    fontSize: "32px",
    marginTop: "16px",
  },
  lineThrough: {
    fontSize: "26px",
  },
}));

function Price({ text, discount, label }) {
  const classes = useStyles();

  console.log(text, discount);

  if (discount > 0) {
    return (
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        style={{
          marginBottom: "25px",
          color: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Typography
          variant="h3"
          color="initial"
          className={classes.lineThrough}
        >
          <b style={{ textDecoration: "line-through" }}>
            {text.toLocaleString()} LKR
          </b>

          {label ? (
            <Chip
              variant="outlined"
              size="small"
              label={label}
              style={{
                marginLeft: "5px",
                border: "2px solid #CC997C",
                color: "#CC997C",
              }}
            />
          ) : null}
        </Typography>

        <Typography variant="h3" color="initial" className={classes.text}>
          <b>{(text - discount).toLocaleString()} LKR</b>
        </Typography>
      </Grid>
    );
  } else {
    return (
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        style={{
          marginBottom: "25px",
          color: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Typography variant="h3" color="initial" className={classes.text}>
          <b>{text.toLocaleString()} LKR</b>
        </Typography>
      </Grid>
    );
  }
}

export default Price;
