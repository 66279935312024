import React, { useState } from "react";
import {
  Badge,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import "../index.css";

//Icons
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import InfoIcon from "@material-ui/icons/Info";
import PrintIcon from "@material-ui/icons/Print";
import { NavLink, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import { AccountCircle } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PhoneIcon from "@material-ui/icons/Phone";

import AppBar from "./AppBar";

const useStyles = makeStyles((theme) => ({
  headingBar: {
    fontSize: 11,
  },
  fsize12: {
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 8,
    },
  },
  iconSize: {
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  centerItem: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: theme.spacing(24),
      fontSize: 12,
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      marginLeft: 0,
    },
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
      marginLeft: 0,
      fontSize: 11,
    },
  },
  bottomAppbar: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "-70px",
    },
  },
  bottomSearchbar: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "70px",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  IconSize: {
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="false" style={{ backgroundColor: "white" }}>
        {/* <Grid container md={12} sm={12} xs={12} justify="space-between">
          <Grid
            md={10}
            sm={12}
            xs={12}
            align="left"
            container
            direction="row"
            style={{ marginTop: "10px" }}
          >
            <Grid>
              <ShoppingCartIcon
                color="primary"
                className={classes.IconSize}
              ></ShoppingCartIcon>
            </Grid>
            <Typography
              className={classes.centerItem}
              style={{
                fontFamily: "AirbnbCerealBook",
                marginLeft: "5px",
              }}
            >
              Free Shipping on orders $100.00 or flat $5 on all orders
            </Typography>
          </Grid>
          <Grid md={2} sm={12} xs={12} style={{ marginTop: "10px" }}>
            <div className={classes.sectionDesktop}>
              <Grid container direction="row" justify="space-between">
                <Grid
                  container
                  direction="row"
                  md={6}
                  sm={2}
                  xs={6}
                  align="right"
                  justify="flex-end"
                >
                  <Grid>
                    <InfoIcon
                      color="primary"
                      className={classes.iconSize}
                      style={{ marginRight: "3px" }}
                    />
                  </Grid>
                  <NavLink to={"#"} style={{ textDecoration: "none" }}>
                    <Typography
                      className={`${classes.headingBar} ${classes.fsize12}`}
                      align="center"
                      style={{
                        fontFamily: "AirbnbCerealBook",
                        color: "#1565c0",
                        marginRight: "10px",
                      }}
                    >
                      About Us
                    </Typography>
                  </NavLink>
                </Grid>

                <Grid container direction="row" md={6} sm={2} xs={6}>
                  <Grid>
                    <PhoneIcon
                      color="primary"
                      className={classes.iconSize}
                      style={{ marginRight: "3px" }}
                    />
                  </Grid>
                  <NavLink to={"#"} style={{ textDecoration: "none" }}>
                    <Typography
                      className={`${classes.headingBar} ${classes.fsize12}`}
                      align="center"
                      style={{
                        fontFamily: "AirbnbCerealBook",
                        color: "#1565c0",
                      }}
                    >
                      Contact Us
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "10px" }} /> */}

        <AppBar />
      </Container>
    </>
  );
}
