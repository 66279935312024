import React from "react";

import { Typography, Grid, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "left",
    color: "#333333",
    textTransform: "capitalize",
  },
}));

export default function CardPrice({ text, discount, label }) {
  const classes = useStyles();

  if (discount > 0) {
    return (
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant="h6" color="initial" className={classes.text}>
          <b
            style={{ textDecoration: "line-through", fontSize: "14px" }}
          >{`${text.toLocaleString()} LKR`}</b>

          {label ? (
            <Chip
              variant="outlined"
              size="small"
              label={label}
              style={{
                marginLeft: "5px",
                border: "2px solid red",
                color: "red",
              }}
            />
          ) : null}
        </Typography>

        <Typography
          variant="body1"
          align="left"
          color="primary"
          style={{
            fontSize: "20px",
            fontFamily: "AirbnbCerealMedium",
            color: "red",
          }}
        >
          <b> {`${(text - discount).toLocaleString()} LKR`}</b>
        </Typography>
      </Grid>
    );
  } else {
    return (
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant="h6" color="initial" className={classes.text}>
          <b>{`${text.toLocaleString()} LKR`}</b>
        </Typography>
      </Grid>
    );
  }
}
