import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";

import size from "../images/size.png";

import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  text: {
    color: "rgba(0, 0, 0, 0.8)",
    textAlign: "left",
    fontSize: "16px",
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">SIZE CHART</DialogTitle>
      <img src={size} alt="size" style={{ width: "100%" }} />
    </Dialog>
  );
}

export default function SimpleDialogDemo() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Grid container alignItems="center" justify="space-between">
      <Typography
        variant="overline"
        color="initial"
        className={classes.text}
        style={{ marginRight: "5px" }}
      >
        <b>AVAILABLE SIZES</b>
      </Typography>

      <Typography variant="body2" color="initial">
        View size chart
        <IconButton style={{ color: "black" }} onClick={handleClickOpen}>
          <HelpIcon />
        </IconButton>
      </Typography>

      <SimpleDialog open={open} onClose={handleClose} />
    </Grid>
  );
}
