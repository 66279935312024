import { Container, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";

import { Img } from "react-image";

import { makeStyles } from "@material-ui/core/styles";

// import Text from "../components/Text";
import HeartIcon from "../components/HeartIcon";
import ColorIcon from "../components/ColorIcon";
import Price from "../components/Price";
import Spinner from "../components/Spinner";
import CardTitle from "./CardTitle";
import CardPrice from "./CardPrice";

import { db } from "../util/config";
import { AuthContext } from "../util/AuthProvider";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  zoom: {
    "&:hover": {
      transform: `scale(1.05)`,
      transition: `transform .5s ease`,
    },
  },
}));

toast.configure();

function ProductCard({ title, price, discount, image, id }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const { dispatch, cart, currentUser } = useContext(AuthContext);

  const successToast = (message) => {
    toast(message, {
      className: classes.toastSuccess,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
    });
  };

  const errorToast = (message) => {
    toast(message, {
      className: classes.toastError,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
    });
  };

  const addToWishList = async () => {
    setLoading(true);
    try {
      let wishList = [];

      wishList.push(id);

      //see there is an document exists
      const document = await db
        .collection("wish-list")
        .doc(currentUser.uid)
        .get();
      console.log(document);

      if (document.exists === true) {
        const data = await db
          .collection("wish-list")
          .doc(currentUser.uid)
          .update({
            userId: currentUser.uid,
            wishList: firebase.firestore.FieldValue.arrayUnion(id),
          });

        successToast(
          "Item added successfully to wish list. You can manage your wish list in settings"
        );

        setLoading(false);
      } else {
        const data = await db.collection("wish-list").doc(currentUser.uid).set({
          userId: currentUser.uid,
          wishList: wishList,
        });

        console.log(data, "new");
        successToast(
          "Item added successfully to wish list. You can manage your wish list in settings"
        );

        setLoading(false);
      }
    } catch (err) {
      errorToast("Something went wrong. Please try again later");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Grid
      item
      container
      justify="center"
      direction="row"
      className={classes.zoom}
      style={{
        boxShadow: "0px 10px 38px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#FFFFFF",
        marginBottom: "10px",
      }}
      lg={12}
      md={12}
      sm={12}
      xs={12}
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          direction: "row",
          justifyContent: "center",
        }}
      >
        <NavLink to={`/product/${id}`}>
          <Grid container>
            <Img
              src={image}
              loader={<Spinner type="sync" size={10} />}
              alt="Model"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
        </NavLink>
      </Grid>

      <Grid
        container
        item
        alignItems="space-between"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: "15px" }}
      >
        <CardTitle text={title} />

        <Grid
          container
          item
          style={{
            display: "flex",
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item lg={7} md={7}>
            <CardPrice text={`${price} LKR`} />
          </Grid>

          <Grid item lg={5} md={5} style={{ textAlign: "right" }}>
            {loading ? (
              <SyncLoader
                css={override}
                size={8}
                color={"#CC997C"}
                loading={true}
              />
            ) : (
              <HeartIcon color="#CC997C" size="32px" onClick={addToWishList} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProductCard;
