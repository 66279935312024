import React, { useContext } from "react";
import "../index.css";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

//Icons
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import PrintIcon from "@material-ui/icons/Print";
import { useLocation } from "react-router-dom";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import logo from "../images/logo.png";

import CartPopover from "../components/CartPopover";

import { db, app } from "../util/config";
import { AuthContext } from "../util/AuthProvider";

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      padding: "0px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexGrow: "1",
      },
    },
    sectionMobile: {
      display: "flex",
      flexGrow: "1",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    sectionMobile1: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    fsize12: {
      [theme.breakpoints.up("md")]: {
        fontSize: 12,
        fontFamily: "AirbnbCerealBook",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 11,
        fontFamily: "AirbnbCerealBook",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 8,
        fontFamily: "AirbnbCerealBook",
      },
    },

    Logo: {
      [theme.breakpoints.up("md")]: {
        float: "left",
        width: "100%",
        objectFit: "contain",
      },
      [theme.breakpoints.down("md")]: {
        float: "left",
        width: "100%",
        maxHeight: "70px",
      },
      [theme.breakpoints.down("sm")]: {
        float: "left",
        width: "100%",
        objectFit: "contain",
      },
    },
    alignmentInMobileView: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "right",
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "right",
      },
    },
  })
);

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const { currentUser, cart } = useContext(AuthContext);

  console.log(cart, currentUser);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleClose}
    >
      <NavLink to="/settings">
        <MenuItem onClick={handleClose}>
          <Typography variant="overline" color="initial">
            SETTINGS
          </Typography>
        </MenuItem>
      </NavLink>

      <NavLink to="/wishlist">
        <MenuItem onClick={handleClose}>
          <Typography variant="overline" color="initial">
            WISHLST
          </Typography>
        </MenuItem>
      </NavLink>

      <NavLink to="/orders">
        <MenuItem onClick={handleClose}>
          <Typography variant="overline" color="initial">
            MY ORDERS
          </Typography>
        </MenuItem>
      </NavLink>

      {currentUser ? (
        <MenuItem style={{ display: "flex", alignItems: "center" }}>
          <ExitToAppOutlinedIcon
            color="primary"
            style={{
              fontSize: 16,
              marginRight: "8px",
            }}
          />
          <Typography
            onClick={() =>
              app
                .auth()
                .signOut()
                .then(() => {
                  handleClose();
                })
                .catch((err) => console.log(err))
            }
            variant="overline"
            color="initial"
          >
            LOG OUT
          </Typography>
        </MenuItem>
      ) : null}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/womens-home"} style={{ color: "black" }}>
            WOMEN
          </NavLink>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/mens-home"} style={{ color: "black" }}>
            MENS
          </NavLink>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/kids-home"} style={{ color: "black" }}>
            KIDS WEAR
          </NavLink>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/brands-home"} style={{ color: "black" }}>
            {" "}
            BRANDS
          </NavLink>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/promotions"} style={{ color: "black" }}>
            {" "}
            PROMOTIONS
          </NavLink>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/wholesale"} style={{ color: "black" }}>
            {" "}
            WHOLESALE
          </NavLink>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/aboutus"} style={{ color: "black" }}>
            {" "}
            ABOUT US
          </NavLink>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="overline" color="initial">
          <NavLink to={"/contactus"} style={{ color: "black" }}>
            {" "}
            CONTACT US
          </NavLink>
        </Typography>
      </MenuItem>

      {currentUser ? (
        <MenuItem style={{ display: "flex", alignItems: "center" }}>
          <ExitToAppOutlinedIcon
            color="primary"
            style={{
              fontSize: 16,
              marginRight: "8px",
            }}
          />
          <Typography
            onClick={() =>
              app
                .auth()
                .signOut()
                .then(() => {
                  props.history.push("/login");
                })
                .catch((err) => console.log(err))
            }
            variant="overline"
            color="initial"
          >
            <b>LOG OUT</b>
          </Typography>
        </MenuItem>
      ) : (
        <>
          <NavLink to={"/login"} style={{ color: "black" }}>
            <MenuItem>
              <Typography variant="overline" color="initial">
                <b>LOGIN</b>
              </Typography>
            </MenuItem>
          </NavLink>

          <NavLink to={"/register"} style={{ color: "black" }}>
            <MenuItem>
              <Typography variant="overline" color="initial">
                <b>REGISTER</b>
              </Typography>
            </MenuItem>
          </NavLink>
        </>
      )}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        elevation="0"
        position="static"
        style={{
          margin: "0px",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        <Toolbar style={{ backgroundColor: "white" }}>
          <div className={classes.sectionDesktop}>
            <Grid container alignItems="center" justify="space-around">
              <Grid item md={2} sm={2} xs={2}>
                <NavLink to="/">
                  <img
                    className={classes.Logo}
                    style={{ textAlign: "left", float: "left" }}
                    src={logo}
                    alt="logo"
                  />
                </NavLink>
              </Grid>

              {/* <Grid container alignItems="center" justify="center" item md={6}>
                <ButtonGroup
                  variant="text"
                  style={{ color: "black" }}
                  aria-label="text primary button group"
                >
                  <Button>
                    <Typography
                      variant="overline"
                      style={{ color: "black", fontSize: "12px" }}
                    >
                      Home
                    </Typography>
                  </Button>
                  <Button>
                    <Typography
                      variant="overline"
                      style={{ color: "black", fontSize: "12px" }}
                    >
                      Mens
                    </Typography>
                  </Button>
                  <Button>
                    <Typography
                      variant="overline"
                      style={{ color: "black", fontSize: "12px" }}
                    >
                      Women
                    </Typography>
                  </Button>
                  <Button>
                    <Typography
                      variant="overline"
                      style={{ color: "black", fontSize: "12px" }}
                    >
                      Kids wear
                    </Typography>
                  </Button>
                  <Button>
                    <Typography
                      variant="overline"
                      style={{ color: "black", fontSize: "12px" }}
                    >
                      Promotions
                    </Typography>
                  </Button>
                  <Button>
                    <Typography
                      variant="overline"
                      style={{ color: "black", fontSize: "12px" }}
                    >
                      Wholesale
                    </Typography>
                  </Button>
                </ButtonGroup> */}

              <Grid container alignItems="center" justify="center" item md={8}>
                <Grid
                  item
                  md={2}
                  style={{
                    boxSizing: "border-box",
                    borderRight: "1px solid #A9A9A9",
                  }}
                >
                  <NavLink to="/jewels-home" style={{ textDecoration: "none" }}>
                    <Typography
                      align="center"
                      variant="body1"
                      style={{
                        fontFamily: "AirbnbCerealMedium",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#212121",
                      }}
                    >
                      <b>JEWELS</b>
                    </Typography>
                  </NavLink>
                </Grid>
                <Grid
                  item
                  md={2}
                  style={{
                    boxSizing: "border-box",
                    borderRight: "1px solid #A9A9A9",
                  }}
                >
                  <NavLink to="/gems-home" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "AirbnbCerealMedium",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#212121",
                      }}
                    >
                      <b>GEMS</b>
                    </Typography>
                  </NavLink>
                </Grid>

                <Grid
                  item
                  md={2}
                  style={{
                    boxSizing: "border-box",
                    borderRight: "1px solid #A9A9A9",
                  }}
                >
                  <NavLink to="/promotions" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "AirbnbCerealMedium",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#212121",
                      }}
                    >
                      <b>PROMOTIONS</b>
                    </Typography>
                  </NavLink>
                </Grid>
                <Grid
                  item
                  md={2}
                  style={{
                    boxSizing: "border-box",
                    borderRight: "1px solid #A9A9A9",
                  }}
                >
                  <NavLink to="/aboutus" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "AirbnbCerealMedium",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#212121",
                      }}
                    >
                      <b>ABOUT US</b>
                    </Typography>
                  </NavLink>
                </Grid>
                <Grid
                  item
                  md={2}
                  style={{
                    boxSizing: "border-box",
                  }}
                >
                  <NavLink to="/contactus" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "AirbnbCerealMedium",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#212121",
                      }}
                    >
                      <b>CONTACT US</b>
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
                item
                md={2}
              >
                <Grid
                  container
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  style={{ textAlign: "right" }}
                >
                  {currentUser ? (
                    <>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        style={{ textAlign: "right" }}
                      >
                        <ButtonGroup disableElevation variant="text">
                          <CartPopover cart={cart} />
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <AccountCircleRoundedIcon
                              color="primary"
                              fontSize="large"
                            />
                          </Button>
                        </ButtonGroup>
                      </Grid>

                      {/* <Grid
                        item
                        sm={6}
                        xs={6}
                        md={6}
                        style={{ textAlign: "right" }}
                      >
                       
                      </Grid> */}
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        style={{ textAlign: "right" }}
                      >
                        <ButtonGroup disableElevation>
                          <NavLink to="/login">
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                borderRadius: "20px",
                                marginRight: "10px",
                                backgroundColor: "black",
                                color: "white",
                                border: "2px solid black",
                              }}
                            >
                              <b>Login</b>
                            </Button>
                          </NavLink>
                          <NavLink to="/register">
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                borderRadius: "20px",
                                color: "black",
                                border: "2px solid black",
                              }}
                            >
                              <b>Register</b>
                            </Button>
                          </NavLink>
                        </ButtonGroup>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className={classes.sectionMobile}>
            <Grid
              container
              sm={12}
              xs={12}
              alignItems="center"
              direction="row"
              justify="space-between"
            >
              <Grid item sm={7} xs={7}>
                <NavLink to="/">
                  <img className={classes.Logo} src={logo} alt="logo" />
                </NavLink>
              </Grid>
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                item
                sm={5}
                xs={5}
              >
                <Grid
                  item
                  sm={9}
                  xs={9}
                  className={classes.alignmentInMobileView}
                >
                  {currentUser ? <CartPopover cart={cart} /> : null}
                </Grid>

                <Grid item sm={3} xs={3}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
