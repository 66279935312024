import React from "react";
import "../index.css";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Container, Divider, Typography } from "@material-ui/core";
import GoogleMap from "./GoogleMap";

//icon
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import logo from "../images/logo.png";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import twitter from "../images/twitter.png";

import { Link, NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      textAlign: "center",
    },
    paper: {
      padding: theme.spacing(2),

      color: theme.palette.text.secondary,
    },
    mTop10: {
      [theme.breakpoints.up("md")]: {
        marginTop: "10px",
      },
    },
    mTop20: {
      [theme.breakpoints.up("md")]: {
        marginTop: "20px",
      },
    },
    mTop15: {
      [theme.breakpoints.up("md")]: {
        marginTop: "15px",
      },
    },
    fontFamily: {
      fontFamily: "AirbnbCerealBook",
    },
    center: {
      [theme.breakpoints.down("xs")]: {},
    },
    mLeft10: {
      [theme.breakpoints.down("xs")]: {
        marginLeft: "30px",
      },
    },
    Center: {
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        alignSelf: "center",
      },
    },
  })
);

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "50px", paddingBottom: "25px" }}
      >
        <Grid container>
          <Grid item container md={12} sm={12} xs={12}>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              style={{
                paddingLeft: "25px",
                paddingRight: "25px",
                paddingTop: "10px",
              }}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <img
                  style={{
                    width: "100%",
                    marginBottom: "25px",
                    maxWidth: "300px",
                  }}
                  src={logo}
                  alt="logo"
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Link
                  to={{ pathname: "https://www.torenshal.com/" }}
                  target="_blank"
                >
                  <img
                    style={{
                      width: "32px",
                      marginBottom: "25px",
                      marginRight: "15px",
                    }}
                    src={fb}
                    alt="fb"
                  />
                </Link>

                <Link
                  to={{ pathname: "https://www.torenshal.com/" }}
                  target="_blank"
                >
                  <img
                    style={{
                      width: "32px",
                      marginBottom: "25px",
                      marginRight: "15px",
                    }}
                    src={insta}
                    alt="insta"
                  />
                </Link>

                <Link
                  to={{ pathname: "https://www.torenshal.com/" }}
                  target="_blank"
                >
                  <img
                    style={{
                      width: "32px",
                      marginBottom: "25px",
                      marginRight: "15px",
                    }}
                    src={twitter}
                    alt="twitter"
                  />
                </Link>
              </Grid>
            </Grid>

            <Grid
              item
              container
              md={6}
              sm={12}
              xs={12}
              className={classes.topMargin}
            >
              <Grid md={4} sm={4} xs={12}>
                <Typography
                  variant="overline"
                  style={{ fontSize: "16px", marginBottom: "25px" }}
                  className={classes.fontFamily}
                >
                  <b>INFORMATION</b>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px" }}
                  variant="body1"
                >
                  <NavLink to={"/checkout"} style={{ color: "black" }}>
                    Cart
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/orders"} style={{ color: "black" }}>
                    Orders
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/wishlist"} style={{ color: "black" }}>
                    Wishlist
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/setings"} style={{ color: "black" }}>
                    Account Details
                  </NavLink>
                </Typography>
              </Grid>
              <Grid md={4} sm={4} xs={12}>
                <Typography
                  variant="overline"
                  style={{ fontSize: "16px", marginBottom: "25px" }}
                  className={classes.fontFamily}
                >
                  <b>CATEGORIES</b>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/jewels-home"} style={{ color: "black" }}>
                    Jewels
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/gems-home"} style={{ color: "black" }}>
                    Gems
                  </NavLink>
                </Typography>

                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/promotions"} style={{ color: "black" }}>
                    Promotions
                  </NavLink>
                </Typography>
              </Grid>
              <Grid md={4} sm={4} xs={12}>
                <Typography
                  variant="overline"
                  style={{ fontSize: "16px", marginBottom: "25px" }}
                  className={classes.fontFamily}
                >
                  <b>OUR LINKS</b>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/privacy-policy"} style={{ color: "black" }}>
                    Privacy Policy
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/return-policy"} style={{ color: "black" }}>
                    Return Policy
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/settings"} style={{ color: "black" }}>
                    Shipping Information
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  {" "}
                  <NavLink to={"/terms"} style={{ color: "black" }}>
                    Terms & Conditions
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/aboutus"} style={{ color: "black" }}>
                    About Us
                  </NavLink>
                </Typography>
                <Typography
                  className={classes.fontFamily}
                  style={{ fontSize: "14px", marginBottom: "5px" }}
                  variant="body1"
                >
                  <NavLink to={"/contactus"} style={{ color: "black" }}>
                    Contact Us
                  </NavLink>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              className={classes.topMargin}
              style={{ marginTop: "10px" }}
            >
              <Typography
                className={classes.fontFamily}
                style={{ fontSize: "14px" }}
                variant="body1"
              >
                <LocationOnIcon
                  style={{ fontSize: "14px", marginRight: "10px" }}
                />
                <b>Jewelleryistic (PVT) LTD.</b> <br />
                NO 50, Vajira Road
                <br />
                Colombo, Sri Lanka
                <br />
                0004 <br />
              </Typography>
              <Typography
                className={classes.fontFamily}
                style={{ fontSize: "14px" }}
                variant="body1"
              >
                <PhoneIcon
                  style={{
                    fontSize: "14px",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                />
                <b>+94 74 0335 444</b>
              </Typography>
              <Typography
                className={classes.fontFamily}
                style={{ fontSize: "14px" }}
                variant="body1"
              >
                <MailIcon
                  style={{
                    fontSize: "14px",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                />
                <b> info@jewelleryistic.com</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "25px" }} />

        <Grid container style={{ marginTop: "25px" }}>
          <Grid item md={12} sm={12} xs={12} style={{}}>
            {/* <Typography variant="body1" color="initial">
              © 2020 Summer Grace Apparels. All Rights Reserved. Digitally
              Crafted by{" "}
              <Link
                to={{ pathname: "https://www.torenshal.com/" }}
                target="_blank"
                style={{ color: "red" }}
              >
                Torenshal
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
