import React from "react";

import { Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "rgba(0, 0, 0, 0.8)",
    textAlign: "left",
    fontSize: "16px",
  },
}));

export default function SubHeading({ text }) {
  const classes = useStyles();

  return (
    <Grid item md={12} sm={12} xs={12} style={{ marginBottom: "25px" }}>
      <Typography variant="overline" color="initial" className={classes.text}>
        <b>{text}</b>
      </Typography>

      <Divider />
    </Grid>
  );
}
