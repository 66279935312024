import React from "react";

import { Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#333333",
    textTransform: "capitalize",
    textAlign: "left",
  },
}));

export default function Heading({ text }) {
  const classes = useStyles();

  return (
    <Grid
      item
      md={12}
      sm={12}
      xs={12}
      style={{ marginBottom: "5px", color: "rgba(0, 0, 0, 0.8)" }}
    >
      <Typography variant="h4" color="initial" className={classes.text}>
        <b>{text}</b>
      </Typography>
    </Grid>
  );
}
