import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Img } from "react-image";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//zoom
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";

import Spinner from "../components/Spinner";

const useStyles = makeStyles((theme) => ({
  slideHeight: {
    width: "100%",
  },
  imageSize: {
    [theme.breakpoints.down("sm")]: {
      width: "50px",
    },
    width: "100px",
  },
}));

export default function ProductImageGallery({ images }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const afterChangeEvent = (e) => {
    console.log(e);
  };

  const classes = useStyles();
  // console.log(images);
  const settings = {
    arrows: true,
    fade: true,
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    autoplaySpeed: 5000,
    autoplay: true,
    cssEase: "linear",
  };

  const settingsThumbs = {
    infinite: false,
    slidesToShow: images.length > 4 ? 4 : images.length,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
  };

  console.log(images.length);

  return (
    <div className="slider-wrapper">
      <Slider
        {...settings}
        asNavFor={nav2}
        ref={(slider) => setSlider1(slider)}
      >
        {images.map((image) => (
          <Grid
            id="slider-img"
            item
            md={12}
            xs={12}
            sm={12}
            className={classes.slideHeight}
          >
            <InnerImageZoom
              className={classes.slideHeight}
              src={image.image}
              zoomSrc={image.image}
              alt="img"
              moveType="pan"
            />
          </Grid>
        ))}
      </Slider>

      <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsThumbs}
          afterChange={afterChangeEvent}
          asNavFor={nav1}
          ref={(slider) => {
            setSlider2(slider);
          }}
        >
          {images.map((image) => (
            <Grid item md={12} xs={12} sm={12} className="slick-slide">
              <Img
                src={image.image}
                loader={<Spinner type="sync" />}
                style={{ textAlign: "center" }}
                className={`slick-slide-image ${classes.imageSize}`}
              />
              {/* <img
                style={{ textAlign: "center" }}
                className={`slick-slide-image ${classes.imageSize}`}
                src={image.image}
                alt="thumb"
              /> */}
            </Grid>
          ))}
        </Slider>
      </div>
    </div>
  );
}
