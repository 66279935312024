import React from "react";

import { Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "center",
    color: "#333333",
    textTransform: "capitalize",
  },
}));

export default function CardTitle({ text }) {
  const classes = useStyles();

  return (
    <Grid item md={12} sm={12} xs={12} style={{ color: "rgba(0, 0, 0, 0.8)" }}>
      <Typography variant="body1" color="initial" className={classes.text}>
        <b>{text}</b>
      </Typography>
    </Grid>
  );
}
