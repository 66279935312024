import { Container, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";

import { Img } from "react-image";

import shirts from "../images/shirts.png";
import party from "../images/party.png";

import { makeStyles } from "@material-ui/core/styles";

// import Text from "../components/Text";
import HeartIcon from "../components/HeartIcon";
import ColorIcon from "../components/ColorIcon";
import Price from "../components/Price";
import Spinner from "../components/Spinner";
import CardTitle from "./CardTitle";
import CardPrice from "./CardPrice";

import { db } from "../util/config";
import { AuthContext } from "../util/AuthProvider";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  zoom: {
    "&:hover": {
      transform: `scale(1.05)`,
      transition: `transform .5s ease`,
    },
  },
}));

function CategoryCard({ name, link, url, main }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      item
      xs={12}
      md={3}
      sm={6}
      lg={3}
      style={{
        padding: "25px",
        marginBottom: "25px",
      }}
    >
      <Grid
        style={{
          backgroundColor: "white",
          borderRadius: "16px",
          boxShadow: "0px 10px 38px rgba(0, 0, 0, 0.1)",
        }}
      >
        <NavLink to={`/${main}/${link}`}>
          <Grid item md={12} sm={12} lg={12}>
            <img src={url} alt="cov" style={{ width: "100%" }} />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            lg={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Typography
              variant="overline"
              style={{ fontSize: "18px", color: "black" }}
            >
              <b>{name}</b>
            </Typography>
          </Grid>
        </NavLink>
      </Grid>
    </Grid>
  );
}

export default CategoryCard;
