import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";

import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import { db } from "../util/config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  papal: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  credit: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
  },
  link: {
    display: "flex",
    color: "black",
    textDecoration: "none",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  image: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 150,
      maxHeight: 67,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 150,
      maxHeight: 100,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 100,
      maxHeight: 100,
    },
  },
}));

const Payment = ({
  amount,
  total,
  delivery,
  first_name,
  last_name,
  email,
  phone,
  addressTwo,
  addressOne,
  district,
  province,
  uid,
  products,
  props,
}) => {
  const classes = useStyles();
  const history = useHistory();

  console.log(
    amount,
    total,
    delivery,
    first_name,
    last_name,
    email,
    phone,
    addressOne,
    addressTwo,
    district,
    province,
    uid,
    products
  );

  const [checkoutError, setCheckoutError] = useState(null);

  // const checkoutTest = async () => {
  //   let productsList = [];

  //   products.map((product) => {
  //     return productsList.push({
  //       quantity: product.quantity,
  //       itemId: product.product.itemId,
  //     });
  //   });

  //   console.log("products list", productsList);

  //   const data = await axios.post(
  //     "http://localhost:5001/e-commerce-58b70/us-central1/api/payment",
  //     {
  //       merchant_id: "1215879",
  //       order_id: "dfasdf",
  //       payment_id: "dfasdf",
  //       payhere_amount: "3000.00",
  //       payhere_currency: "LKR",
  //       status_code: "2",
  //       custom_1: "asdasd",
  //       custom_2: JSON.stringify(productsList),
  //       method: "TEST",
  //       status_message: "nc",
  //     }
  //   );

  //   console.log(data);
  // };

  const checkout = () => {
    if (
      products.length > 0 &&
      amount &&
      total &&
      delivery &&
      first_name &&
      last_name &&
      email &&
      phone &&
      addressOne &&
      addressTwo &&
      district.value &&
      province.value
    ) {
      setCheckoutError(null);

      let orderId = uuidv4();

      let productsList = [];

      products.map((product) => {
        return productsList.push({
          quantity: product.quantity,
          itemId: product.product.itemId,
        });
      });

      console.log("products list", productsList);

      // Put the payment variables here
      var payment = {
        sandbox: true,
        merchant_id: "1215879", // Replace your Merchant ID
        return_url: "http://localhost:3000/orders", // Important
        cancel_url: "http://localhost:3000/checkout", // Important
        notify_url:
          "https://us-central1-e-commerce-58b70.cloudfunctions.net/api/payment",
        order_id: orderId,
        items: ["shirt", "trovser"],
        amount: amount,
        total: total,
        fees: delivery,
        currency: "LKR",
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        address: `${addressOne} + ${addressTwo} + ${district.value} + ${province.value}`,
        city: "Colombo", //this needs to be updated
        country: "Sri Lanka",
        custom_1: uid,
        custom_2: JSON.stringify(productsList),
      };

      console.log(payment);

      window.payhere.startPayment(payment);

      // Called when user completed the payment. It can be a successful payment or failure
      window.payhere.onCompleted = async function onCompleted(orderId) {
        try {
          console.log("Payment completed. OrderID:" + orderId);

          let productsList = [];

          console.log("here");
          products.map((product) => {
            return productsList.push({
              productId: product.id,
              price: product.price,
              quantity: product.quantity,
              title: product.title,
              items: product.product,
            });
          });
          console.log("here", productsList);

          const data = await db
            .collection("orders")
            .doc(orderId)
            .set({
              date: moment().format("DD-MM-YYYY"),
              order_id: orderId,
              uid: uid,
              first_name: first_name,
              last_name: last_name,
              email: email,
              phone: phone,
              address: `${addressOne} + ${addressTwo} + ${district.value} + ${province.value}`,
              country: "Sri Lanka",
              amount: amount,
              total: total,
              fees: delivery,
              items: productsList,
              currency: "LKR",
              order_status: "PROCESSING",
            });

          console.log(data, "document created. OrderID:" + orderId);
          history.push("/orders");
        } catch (err) {
          console.log(err);
          console.log("something went wrong. OrderID:" + orderId);
        }

        //Note: validate the payment and show success or failure page to the customer
      };

      // Called when user closes the payment without completing
      window.payhere.onDismissed = function onDismissed() {
        //Note: Prompt user to pay again or show an error page
        console.log("Payment dismissed");
      };

      // Called when error happens when initializing payment such as invalid parameters
      window.payhere.onError = function onError(error) {
        // Note: show an error page
        console.log("Error:" + error);
      };
    } else {
      setCheckoutError(
        "Make sure you have filled necessary payment information and cart is not empty"
      );
    }
  };

  return (
    <>
      <Button
        style={{
          color: "white",
          height: "48px",
          marginBottom: "25px",
          // border: "2px solid #3699ff",
        }}
        fullWidth
        onClick={checkout}
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<ShoppingCartOutlinedIcon />}
      >
        <Typography
          variant="overline"
          color="initial"
          style={{ fontSize: "14px" }}
        >
          <b>PROCEED TO CHECKOUT</b>
        </Typography>
      </Button>

      {checkoutError ? (
        <Typography variant="body2" color="primary">
          {checkoutError}
        </Typography>
      ) : null}
    </>
  );
};

export default Payment;
