import React from "react";
import { Typography } from "@material-ui/core";

export default function Label({ text }) {
  return (
    <div>
      <Typography variant="overline" display="block">
        <b style={{ float: "left", marginBottom: "5px" }}>{text}</b>
      </Typography>
    </div>
  );
}
