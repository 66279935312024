import React from "react";

export default function Box({ children }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
      }}
    >
      {children}
    </div>
  );
}
