import { firebase } from "@firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMJWvl6Urq0-aJKUTri4yVUX8YjFKdyoM",
  authDomain: "jewellery-5d61d.firebaseapp.com",
  projectId: "jewellery-5d61d",
  storageBucket: "jewellery-5d61d.appspot.com",
  messagingSenderId: "468588158684",
  appId: "1:468588158684:web:b026a239a1fef77ee95e19",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

//db Firebase
const db = firebase.firestore();

//storageBucket
const bucket = firebase.storage();

// Create a root reference
const storageRef = firebase.storage().ref();

export { app, db, bucket, storageRef };
