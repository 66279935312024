import React from "react";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({}));

function ColorIcon({ color, currentColor, selectedColor, size }) {
  const classes = useStyles();

  console.log(selectedColor, color);

  if (selectedColor === color) {
    return (
      <div
        onClick={() => currentColor(color)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          float: "left",
          width: size,
          height: size,
          borderRadius: size,
          color: "black",
          backgroundColor: color,
          textAlign: "center",
          fontSize: "14px",
          marginRight: "14px",
          boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
          cursor: "pointer",
        }}
      >
        <IconButton aria-label="delete">
          <CheckIcon style={{ color: "white" }} />
        </IconButton>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => currentColor(color)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          float: "left",
          width: size,
          height: size,
          borderRadius: size,
          color: "black",
          backgroundColor: color,
          textAlign: "center",
          fontSize: "14px",
          marginRight: "14px",
          boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
          cursor: "pointer",
        }}
      ></div>
    );
  }
}

export default ColorIcon;
