import React from "react";

import { Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "rgba(0, 0, 0, 0.8)",
    whiteSpace: "pre-line",
    textAlign: "left",
  },
}));

export default function Paragraph({ text }) {
  const classes = useStyles();

  return (
    <Grid item md={12} sm={12} xs={12} style={{ marginBottom: "25px" }}>
      <Typography variant="body2" color="initial" className={classes.text}>
        {text}
      </Typography>
    </Grid>
  );
}
