import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import SyncLoader from "react-spinners/SyncLoader";

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: "flex",
    direction: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));

// const override = css`
//   // display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

export default function Spinner({ type, size }) {
  const classes = useStyles();

  if (type === "propagate") {
    return (
      <Grid container component="main" spacing={3}>
        <Grid className={classes.spinner} item md={12} sm={12} xs={12}>
          <PropagateLoader
            // css={override}
            size={15}
            color={"#CC997C"}
            loading={true}
          />
        </Grid>
      </Grid>
    );
  }

  if (type === "sync") {
    return (
      <Grid container component="main" spacing={3}>
        <Grid className={classes.spinner} item md={12} sm={12} xs={12}>
          <SyncLoader
            // css={override}
            size={size}
            color={"#CC997C"}
            loading={true}
          />
        </Grid>
      </Grid>
    );
  }
}
