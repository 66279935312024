import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover({ cart }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={cart.products.length} color="primary">
          <LocalMallIcon
            color="primary"
            style={{
              fontSize: 32,
              marginRight: "8px",
              marginLeft: "16px",
            }}
          />{" "}
        </Badge>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography className={classes.typography}>
          <List component="nav" aria-label="main mailbox folders">
            {cart.products.map((product) => {
              return (
                <>
                  <ListItem button>
                    <ListItemIcon style={{ marginRight: "15px" }}>
                      <img
                        style={{ maxWidth: "50px" }}
                        src={product.product.image}
                        alt="product_image"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${parseInt(
                        product.price
                      ).toLocaleString()} LKR`}
                      secondary={`x ${product.quantity}`}
                    />
                  </ListItem>

                  <Divider />
                </>
              );
            })}
          </List>

          <NavLink to="/checkout">
            <Button
              style={{
                color: "white",
                height: "48px",
                // border: "2px solid #3699ff",
              }}
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<ShoppingCartOutlinedIcon />}
            >
              <Typography
                variant="overline"
                color="initial"
                style={{ textAlign: "center" }}
              >
                VIEW CART
              </Typography>
            </Button>
          </NavLink>
        </Typography>
      </Popover>
    </div>
  );
}
