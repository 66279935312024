import React from "react";

import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ProductCardSlider from "./ProductCardSlider";

const useStyles = makeStyles((theme) => ({
  pad: {
    padding: "15px",

    backgroundColor: "blue",
  },
}));

export default function ProductSlider({ products }) {
  const classes = useStyles();

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: products.length > 4 ? 4 : products.length,
    slidesToScroll: 1,
    className: "slides",
    autoplaySpeed: 4000,
    autoplay: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: products.length > 4 ? 4 : products.length,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: products.length > 3 ? 3 : products.length,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: products.length > 2 ? 2 : products.length,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (products.length > 3) {
    return (
      <Slider {...settings}>
        {products.map((product) => (
          <ProductCardSlider
            id={product.productId}
            title={product.title}
            price={product.price}
            discount={product.discount}
            image={product.images[0]}
            label={product.label}
          />
        ))}
      </Slider>
    );
  } else {
    return <p>Please add more products</p>;
  }
}
