import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";

//icons
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import HeadsetIcon from "@material-ui/icons/Headset";
import RefreshIcon from "@material-ui/icons/Refresh";

function FeaturesSection() {
  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "25px", marginBottom: "50px" }}
    >
      <Grid container direction="row">
        <Grid
          container
          item
          md={4}
          sm={12}
          xs={12}
          alignItems="center"
          style={{
            display: "flex",
            justifyContent: "center",
            // backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 10px 38px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid item md={1} sm={1} xs={12}>
            {" "}
            <LocalShippingIcon />
          </Grid>
          <Grid
            item
            direction="column"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            <b>WE SHIP EVERYWHERE</b>
            <Typography>World-Wide deliveries</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={4}
          sm={12}
          xs={12}
          alignItems="center"
          style={{
            display: "flex",
            justifyContent: "center",
            // backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 10px 38px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid item md={1} sm={1} xs={12}>
            {" "}
            <RefreshIcon />
          </Grid>
          <Grid
            item
            direction="column"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            <b>EXCHANGE OR RETURAN</b>
            <Typography>7 Days Money Back Guaranty</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={4}
          sm={12}
          xs={12}
          alignItems="center"
          style={{
            display: "flex",
            justifyContent: "center",
            // backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 10px 38px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid item md={1} sm={1} xs={12}>
            {" "}
            <HeadsetIcon />
          </Grid>
          <Grid
            item
            direction="column"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            <b>SUPPORT</b>
            <Typography>24/7 Online Support </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FeaturesSection;
