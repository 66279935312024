import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Label from "./Label";

export default function LabelAndDetail({ label, detail, marginBottom }) {
  return (
    <>
      <Grid item md={12} sm={12} xs={12} style={{ textAlign: "left" }}>
        <Label text={label} />
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        style={{ textAlign: "left", marginBottom: marginBottom }}
      >
        <Typography variant="body2" color="initial">
          {detail}
        </Typography>
      </Grid>
    </>
  );
}
