import React, { useState, useEffect, useContext } from "react";
import { useForm, ErrorMessage, Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
// import bag from "../Images/bag.png";

import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  Container,
  CircularProgress,
  Breadcrumbs,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  Checkbox,
  ListItemText,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../util/AuthProvider";

import { db, bucket } from "../util/config";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  heading: {
    marginTop: "25px",
    marginBottom: "25px",
    textAlign: "left",
  },
  spinner: {
    display: "flex",
    direction: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "90vh",
  },
  subheading: {
    textAlign: "left",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
    paddingRight: "25px",
    fontSize: "16px",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
  },
  boxContainer: {
    paddingTop: "10px",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingBottom: "10px",
  },
  link: {
    display: "flex",
    color: "black",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  inputLabel: {
    marginTop: "-5px",
    fontWeight: "bold",
  },

  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  image: {
    maxwidth: "100px",
    maxHeight: "100px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CheckoutCard(props) {
  const classes = useStyles();

  const [loginErrors, setLoginErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const [sizes, setSizes] = useState([]);
  const { currentUser, cart } = useContext(AuthContext);

  const [products, setProducts] = useState([]);

  const [discount, setDiscount] = useState("");

  useEffect(() => {
    setLoading(true);

    const products = db
      .collection("products")
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot);
        let productsList = [];

        console.log(querySnapshot.docs);
        querySnapshot.docs.map((doc) => {
          productsList.push(doc.data());
          console.log(doc.data());
        });
        setProducts(productsList);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleClick = (id) => {
    console.log(id);

    const item = products.filter((product) => product.productId !== id);
    console.log(item);

    setProducts(item);
  };

  console.log(products);

  return (
    <Container maxWidth="false" style={{ alignItems: "center" }}>
      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid className={classes.spinner} item md={12} sm={12} xs={12}>
            <CircularProgress />
            <p style={{ marginLeft: "20px" }}>
              <b>Loading ...</b>
            </p>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            component="main"
            spacing={2}
            style={{
              marginBottom: "25px",
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              align="center"
              style={{ marginTop: "-8px", marginBottom: "-8px" }}
            >
              <TableContainer
                style={{
                  maxHeight: 400,
                }}
              >
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Item Details</b>
                      </TableCell>
                      <TableCell>
                        <b>Title</b>
                      </TableCell>
                      <TableCell>
                        <b>Quantity</b>
                      </TableCell>
                      <TableCell>
                        <b>Colors</b>
                      </TableCell>
                      <TableCell style={{ align: "right" }}>
                        <b>Price</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {products.map((product) => {
                    return (
                      <TableBody>
                        <TableRow key={product.productId}>
                          <TableCell component="th" scope="product">
                            <img
                              className={classes.image}
                              // src={bag}
                              alt="bag"
                            />
                          </TableCell>

                          <TableCell component="th" scope="product">
                            {product.title}
                          </TableCell>
                          <TableCell component="th" scope="product">
                            {product.quantity}
                          </TableCell>
                          <TableCell component="th" scope="product">
                            {product.color}
                          </TableCell>

                          <TableCell component="th" scope="product">
                            {product.price}
                          </TableCell>

                          {/* <TableCell component="th" scope="product">
                                <img
                                  style={{ maxWidth: "100px" }}
                                  src={URL.createObjectURL(product.image[0])}
                                  alt="product_image"
                                />
                              </TableCell> */}

                          {/* <TableCell align="left">
                            <Button
                              variant="outlined"
                              style={{
                                border: "2px solid #3699ff",
                                color: "#3699ff",
                                fontSize: "12px",
                              }}
                              onClick={() =>
                                props.history.push({
                                  pathname: `/edit-products/${product.productId}`,
                                  state: {
                                    product: product,
                                  },
                                })
                              }
                              disableElevation
                            >
                              <b>Edit</b>
                            </Button>
                          </TableCell> */}
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}
