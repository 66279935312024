import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subcontainer: {
    color: "rgba(0, 0, 0, 0.8)",
    whiteSpace: "pre-line",

    paddingTop: "25px",
    paddingBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "25px",
      paddingBottom: "25px",
    },
  },
}));

export default function SingleReview({ name, date, review }) {
  const classes = useStyles();

  return (
    <Grid item container md={6} sm={6} xs={12} className={classes.subcontainer}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant="body1" color="initial">
          <b>{name}</b>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant="overline" color="initial">
          {date}
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12} style={{ marginTop: "15px" }}>
        <Typography variant="body2" color="initial">
          {review}
        </Typography>
      </Grid>
    </Grid>
  );
}
