import React, { useState, useEffect } from "react";
import { useForm, ErrorMessage, Controller } from "react-hook-form";
import Select from "react-select";

import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import SideBarColorIcon from "../components/SideBarColorIcon";
import SidebarSizeIcon from "../components/SidebarSizeIcon";
import SidebarLabel from "../components/SidebarLabel";

import {
  Grid,
  Typography,
  Divider,
  Button,
  FormHelperText,
  Slider,
} from "@material-ui/core";

import { db, bucket } from "../util/config";

const useStyles = makeStyles((theme) => ({}));

const Sidebar = ({ setSearchResult }) => {
  const classes = useStyles();

  const { register, handleSubmit, control, errors } = useForm();

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [value, setValue] = useState([20, 37]);

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true);

    const categories = db
      .collection("categories")
      .orderBy("label")
      .get()
      .then((querySnapshot) => {
        let categoryList = [];

        console.log(querySnapshot.docs);
        querySnapshot.docs.map((doc) => {
          categoryList.push(doc.data());
          console.log(doc.data());
        });
        setCategories(categoryList);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    const subcategories = db
      .collection("sub-categories")
      .orderBy("label")
      .get()
      .then((querySnapshot) => {
        let subcategoryList = [];

        console.log(querySnapshot.docs);
        querySnapshot.docs.map((doc) => {
          subcategoryList.push(doc.data());
          console.log(doc.data());
        });

        setSubcategories(subcategoryList);

        console.log(subcategoryList);

        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    const colors = db
      .collection("colors")
      .orderBy("label")
      .get()
      .then((querySnapshot) => {
        let colorList = [];

        console.log(querySnapshot.docs);
        querySnapshot.docs.map((doc) => {
          colorList.push(doc.data());
          console.log(doc.data());
        });
        setColors(colorList);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    const sizes = db
      .collection("sizes")
      .orderBy("label")
      .get()
      .then((querySnapshot) => {
        let sizeList = [];

        console.log(querySnapshot.docs);
        querySnapshot.docs.map((doc) => {
          sizeList.push(doc.data());
          console.log(doc.data());
        });
        setSizes(sizeList);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  function valuetext(value) {
    return `${value} LKR`;
  }

  const currentColor = (color) => {
    console.log(color);

    setSelectedColor(color);
  };

  const currentSize = (color) => {
    console.log(color);

    setSelectedSize(color);
  };

  const search = async (data) => {
    console.log(data);
    console.log(selectedColor);
    console.log(selectedSize);
    console.log(value);

    try {
      let query = db.collection("products");

      if (data.category) {
        query = query.where("category", "==", data.category);
      }
      if (data.subcategory) {
        query = query.where("subcategory", "==", data.subcategory);
      }

      console.log(query);

      const seachResult = await query.get();

      console.log(seachResult);

      let productList = [];

      seachResult.docs.map((doc) => {
        productList.push(doc.data());
      });

      setSearchResult(productList);
    } catch (err) {
      console.log(err);
      setSearchResult([]);
    }
  };

  return (
    <>
      <Grid item md={12} sm={12} xs={12} style={{ textAlign: "left" }}>
        <SidebarLabel text="Category" />
      </Grid>

      <form style={{ width: "100%" }}>
        <Grid>
          {categories.length > 0 ? (
            <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
              <Controller
                name="category"
                as={Select}
                options={categories}
                control={control}
                defaultValue={categories[0]}
                rules={{ required: "Category is required" }}
              />

              <FormHelperText>
                {errors.category && errors.category.message}
              </FormHelperText>
            </Grid>
          ) : (
            <Grid item md={12} sm={12} xs={12} style={{ textAlign: "left" }}>
              <p>Please Add categories</p>
            </Grid>
          )}

          <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
            <SidebarLabel text="Sub-category" />
          </Grid>

          {subcategories.length > 0 ? (
            <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
              <Controller
                name="subcategory"
                as={Select}
                options={subcategories}
                defaultValue={subcategories[0]}
                control={control}
                rules={{ required: "Sub-category is required" }}
              />

              <FormHelperText>
                {errors.subcategory && errors.subcategory.message}
              </FormHelperText>
            </Grid>
          ) : (
            <Grid item md={12} sm={12} xs={12} style={{ textAlign: "left" }}>
              <p>Please Add subcategories</p>
            </Grid>
          )}
        </Grid>
      </form>

      <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
        <SidebarLabel text="COLORS" />
      </Grid>

      <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
        {colors.map((color) => (
          <SideBarColorIcon
            color={color.hex}
            currentColor={currentColor}
            selectedColor={selectedColor}
            size="32px"
          />
        ))}
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        style={{ marginTop: "25px", textAlign: "left" }}
      >
        <SidebarLabel text="SIZE" />
      </Grid>

      <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
        {sizes.map((size) => (
          <SidebarSizeIcon
            size={size.label}
            currentSize={currentSize}
            selectedSize={selectedSize}
            iconSize="40px"
          />
        ))}
      </Grid>
      <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
        <SidebarLabel text="PRICE RANGE" />
      </Grid>

      <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          min={0}
          max={10000}
          step={500}
        />
      </Grid>

      <Grid item md={12} sm={12} xs={12} style={{ marginTop: "25px" }}>
        <Button
          onClick={handleSubmit(search)}
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "30px", height: "50px" }}
        >
          Apply Search
        </Button>
      </Grid>
    </>
  );
};

export default Sidebar;
