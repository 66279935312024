import React from "react";
import { Grid } from "@material-ui/core";

function SizeIcon({
  size,
  currentSize,
  selectedProduct,
  selectedSize,
  index,
  iconSize,
}) {
  console.log(size, selectedSize, index, selectedProduct);

  if (selectedSize === size && index.itemId === selectedProduct[0].itemId) {
    return (
      <div
        onClick={() => currentSize(size)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          float: "left",
          width: iconSize,
          height: iconSize,
          borderRadius: iconSize,
          color: "white",
          backgroundColor: "#FF0000",
          textAlign: "center",
          fontSize: "14px",
          marginRight: "14px",
          // boxShadow: `0px 10px 18px rgba(221, 230, 237, 5)`,
          cursor: "pointer",
        }}
      >
        <b>{size}</b>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => currentSize(size)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          float: "left",
          width: iconSize,
          height: iconSize,
          borderRadius: iconSize,
          color: "black",
          backgroundColor: "#EEEEEE",
          textAlign: "center",
          fontSize: "14px",
          marginRight: "14px",
          // boxShadow: `0px 10px 18px rgba(221, 230, 237, 5)`,
          cursor: "pointer",
        }}
      >
        <b>{size}</b>
      </div>
    );
  }
}

export default SizeIcon;
