import { Container, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";

import { Img } from "react-image";

import { makeStyles } from "@material-ui/core/styles";

// import Text from "../components/Text";
import HeartIcon from "../components/HeartIcon";
import ColorIcon from "../components/ColorIcon";
import Price from "../components/Price";
import Spinner from "../components/Spinner";
import CardTitle from "./CardTitle";
import CardPrice from "./CardPrice";

import { db } from "../util/config";
import { AuthContext } from "../util/AuthProvider";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  zoom: {
    "&:hover": {
      transform: `scale(1.03)`,
      transition: `transform .5s ease`,
    },
  },
}));

toast.configure();

function ProductCardSlider({ title, price, discount, image, id, label }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(false);

  const { dispatch, cart, currentUser } = useContext(AuthContext);

  const getWishList = async () => {
    setLoading(true);

    try {
      const data = await db.collection("wish-list").doc(currentUser.uid).get();

      console.log(data.data());

      data.data().wishList.map((wish) => {
        console.log(wish);
        if (wish === id) {
          setStatus(true);
        }
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWishList();
  }, []);

  const successToast = (message) => {
    toast(message, {
      className: classes.toastSuccess,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
    });
  };

  const errorToast = (message) => {
    toast(message, {
      className: classes.toastError,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
    });
  };

  const addToWishList = async () => {
    setLoading(true);
    try {
      let wishList = [];

      wishList.push(id);

      //see there is an document exists
      const document = await db
        .collection("wish-list")
        .doc(currentUser.uid)
        .get();
      console.log(document);

      if (document.exists === true) {
        const data = await db
          .collection("wish-list")
          .doc(currentUser.uid)
          .update({
            userId: currentUser.uid,
            wishList: firebase.firestore.FieldValue.arrayUnion(id),
          });

        successToast(
          "Item added successfully to wish list. You can manage your wish list in settings"
        );

        await getWishList();
        //need to reload if there are two cards wth same data.cuz once change doesnt affect to the other one
        // window.location.reload();
      } else {
        const data = await db.collection("wish-list").doc(currentUser.uid).set({
          userId: currentUser.uid,
          wishList: wishList,
        });

        console.log(data, "new");
        successToast(
          "Item added successfully to wish list. You can manage your wish list in settings"
        );

        setLoading(false);
      }
    } catch (err) {
      errorToast("Something went wrong. Please try again later");
      setLoading(false);
      console.log(err);
    }
  };
  console.log(status, id);

  return (
    <Grid style={{ padding: "15px", height: "100%", marginBottom: "15px" }}>
      <Grid
        container
        alignItems="stretch"
        justify="center"
        direction="row"
        className={classes.zoom}
        style={{
          // boxShadow: "0px 10px 38px rgba(0, 0, 0, 0.05)",
          backgroundColor: "#ffffff",
          marginBottom: "10px",
          height: "100%",
        }}
      >
        <NavLink to={`/product/${id}`}>
          <Img
            src={image}
            loader={<Spinner type="sync" size={12} />}
            alt="Model"
            style={{ width: "100%" }}
          />
        </NavLink>

        <Grid
          container
          item
          alignItems="space-between"
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ padding: "15px", paddingTop: "0px", paddingBottom: "0px" }}
        >
          <CardTitle text={title} />

          <Grid
            container
            item
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item lg={9} md={9}>
              <CardPrice
                text={parseInt(price)}
                discount={parseInt(discount)}
                label={label}
              />
              {/* <Discount text={`${discount} LKR`} /> */}
            </Grid>

            <Grid
              item
              lg={3}
              md={3}
              style={{ textAlign: "right", marginBottom: "15px" }}
            >
              {loading ? (
                <SyncLoader
                  css={override}
                  size={8}
                  color={"#FF0000"}
                  loading={true}
                />
              ) : (
                <HeartIcon
                  color="red"
                  size="32px"
                  onClick={addToWishList}
                  status={status}
                  id={id}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProductCardSlider;
