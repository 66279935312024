import { Container, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";

import Slider from "react-slick";

import { Img } from "react-image";

import { makeStyles } from "@material-ui/core/styles";

import { db } from "../util/config";
import { AuthContext } from "../util/AuthProvider";

const useStyles = makeStyles((theme) => ({
  zoom: {
    "&:hover": {
      transform: `scale(1.05)`,
      transition: `transform .5s ease`,
    },
  },
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

toast.configure();

function CommentCardSlider() {
  const classes = useStyles();

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    autoplaySpeed: 5000,
    autoplay: true,
    cssEase: "linear",
  };

  return (
    <div>
      <Slider {...settings}>
        <div>
          {" "}
          <p>
            "I'm so happy with the range and availability of the products at The
            Summer Grace. I'm also happy with the product quality and service
            they offered. I will be buying from them."
          </p>
          <p>
            <b>Prabasha Deshappriya - Rathnapura</b>
          </p>
        </div>

        <div>
          <p>
            "I'm so happy with the range and availability of the products at The
            Summer Grace. I'm also happy with the product quality and service
            they offered. I will be buying from them."
          </p>
          <p>
            <b>Dushshantha Pathirathna - Rathnapura</b>
          </p>
        </div>
        <div>
          <p>
            " I'm so happy with the range and availability of the products at
            The Summer Grace. I'm also happy with the product quality and
            service they offered. I will be buying from them."
          </p>
          <p>
            <b>Chamira Gunawardhana - Horana</b>
          </p>
        </div>
        <div>
          <p>
            {" "}
            "I'm so happy with the range and availability of the products at The
            Summer Grace. I'm also happy with the product quality and service
            they offered. I will be buying from them."
          </p>
          <p>
            <b>Kalana Pallemulla - Kandy</b>
          </p>
        </div>
      </Slider>
    </div>
  );
}

export default CommentCardSlider;
