import React from "react";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import { Grid, IconButton, makeStyles } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({}));

function SideBarColorIcon({ color, currentColor, selectedColor, size }) {
  const classes = useStyles();

  // console.log(selectedColor, color);

  if (selectedColor === color) {
    return (
      <div
        onClick={() => currentColor(color)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          float: "left",
          width: size,
          height: size,
          borderRadius: size,
          color: "black",
          backgroundColor: color,
          textAlign: "center",
          fontSize: "14px",
          marginRight: "14px",
          marginBottom: "14px",
          boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
          cursor: "pointer",
        }}
      >
        <CheckIcon style={{ color: "white" }} />
      </div>
    );
  } else {
    return (
      <div
        onClick={() => currentColor(color)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          float: "left",
          width: size,
          height: size,
          borderRadius: size,
          color: "black",
          backgroundColor: color,
          textAlign: "center",
          fontSize: "14px",
          marginRight: "14px",
          marginBottom: "14px",
          boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
          cursor: "pointer",
        }}
      ></div>
    );
  }
}

export default SideBarColorIcon;
